/* Import the Roboto font from Google Fonts */
/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap'); */

.selector {
  font-family: 'Roboto', sans-serif;
}

.table {
  min-width: 650px;
}

.add-button {
  margin-left: 300px;
}

.addbutton {
  background-color: #4caf50;
  /* Green */
  border: none;
  color: white;
  padding: 10px;
  text-align: center;
  font-family: 'Segoe UI';
  text-decoration: none;
  display: inline-block;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin: 4px 2px;
  margin-left: 970px;
  margin-bottom: 5px;
  cursor: pointer;
  border-radius: 12px;
}

.closeicon {
  margin-bottom: 5px;
  margin-left: 340px;
  color: white;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 5px;
}

.grid-container > div {
  text-align: left;
  font-size: 20px;
}

.grid-container > div:nth-child(4) {
  margin-left: 900px;
}
.grid-container > div:nth-child(6) {
  margin-left: 10px;
}

.addform-container {
  display: grid;
  grid-template-columns: auto auto;
  gap: 10px;
}

.addform-container > div {
  align-items: center;
  margin: 10px;
}

.edit-grid > div {
  text-align: left;
  font-size: 20px;
}

.text {
  color: white;
}

.customers {
  /* font-family: 'Roboto', Arial, sans-serif; */
  border-collapse: collapse;
  width: 100%;
}

.customers td,
.customers th {
  padding: 8px;
}

.customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

.customers tr:hover {
  background-color: #ddd;
}

.customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  font-family: 'Roboto', Arial, sans-serif;
  text-align: center;
  background-color: #2874f0;
  color: #ffffff;
}

.editicon {
  color: #1976d2;
  cursor: pointer;
}

.deleteicon {
  color: red;
  cursor: pointer;
}

.snackbar {
  width: 100%;
}

.dialog-title {
  background-color: #04aa6d;
  color: white;
}

.myButton {
  text-transform: none;
}

.myButton.variantContained {
  background-color: #00cc00;
  color: #ffffff;
  padding: 5px 5px;
  border-radius: 5px;
}

.myButton.variantContained:hover {
  background-color: #00cc00;
}

.myadd_Button {
  background-color: #00cc00;
  border: none;
  color: white;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  border-radius: 12px;
}

.mydelete_Button {
  background-color: red;
  /* Green */
  border: none;
  color: white;
  margin-right: 40px;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  border-radius: 12px;
}

.mycancel_Button {
  background-color: gray;
  /* Green */
  border: none;
  color: white;
  margin-right: 2px;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  border-radius: 12px;
}

.video {
  width: 200px;
  height: 200px;
}
