.gray-line {
  border: 0.25px dashed #ccc;
}
.drawer-align {
  height: 13px !important;
}
.margin {
  margin-left: 5px !important;
}
.point {
  fill: #212529 !important;
  cursor: pointer;
}
.icon1 {
  color: red;
  font-size: 16px;
  cursor: pointer;
}
.icon2 {
  color: green;
  font-size: 16px;
  cursor: pointer;
}
.icon3 {
  color: blue;
  font-size: 16px;
  cursor: pointer;
}

.link {
  text-decoration: none !important;
}
