#edit-button {
  cursor: pointer;
  color: #1976d2;
}

#delete-button {
  cursor: pointer;
  color: #f7364a;
}

.textfield-search-label {
  margin: 0px;
  width: 29ch;
}

#reset-button {
  margin-left: 80px;
}

#reset-button-drawer {
  margin-left: 80px;
}

#reset-button-vendors {
  margin-left: 10px;
}

#add-button {
  margin-left: 650px;
}

#add-button-role {
  margin-left: 650px;
}

#add-button-drawer {
  margin-left: 200px !important;
}

#add-button-drawer-role {
  margin-left: 400px;
}

/* Table allignment */
#table-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

#table {
  min-width: 650px;
}

#table-header {
  background: #dfdcda;
}

/* add permission */
#select-multiple-chip {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.dialog-title {
  background-color: #97cc00;
}

/* submit button for user */
.submit-button {
  background-color: #97cc00;
  color: #fff;
}

.delete-dialog {
  background-color: #97cc00;
}

/* Table heading alignment */
#table-header-align {
  font-weight: 600;
  font-size: 16px;
}

#grid-margin {
  margin-top: 8px;
}

/* .table-header-align {
    font-weight: 600;
    font-size: 16px;
} */

.custom-cell {
  text-align: right !important;
}
.box {
  border: 1px solid black;
  margin: 1px;
}
.custom-dialog {
  width: 1000px;
}
.custom-expand {
  padding: 30px;
}

#myTextBox input {
  width: 450px; /* Set the width to 200 pixels */
}

.dropdown-container {
  display: flex !important;
  align-items: flex-start !important;
}

.custom-textbox {
  margin-top: 20px !important; /* Set the height to 40 pixels */
}

.custom1-textbox {
  margin-top: 20px !important;
  margin-left: 10px;
}

.custom-submit {
  margin-top: 20px !important;
  margin-right: 50px !important;
}

.custom1-icon {
  margin-top: 20px !important;
  margin-left: 10px;
}

/* .custom-textbox select {
    height: 40px !important;
  }
  .custom1-textbox select {
    height: 40px;
  } */

/* .custom-textbox {
    margin-top: 10px; 
  } */
