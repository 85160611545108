.tab_box1 {
    width: 100%;
}

.tab_box2 {
    border-bottom: 1px solid #e5e5e5;
    border-color: #e5e5e5;

}

.tab_font_style {
    font-size: 16px !important;
    font-weight: 600 !important;
    padding: 10px 0px 10px 0px !important;
    margin: 0px 0px 0px 0px !important;
    text-align: center !important;
    border-bottom: 1px solid #e5e5e5 !important; 
    border-color: #e5e5e5 !important;
}

.tab_font_style_vendors {
    font-size: 20px !important;
    font-weight: 600 !important;
    padding: 10px 0px 10px 0px !important;
    margin: 0px 0px 0px 0px !important;
    text-align: left !important;
    border-bottom: 0px solid #e5e5e5 !important;
    border-color: #e5e5e5 !important;
}

.tab_font_style_subscription {
    font-size: 20px !important;
    font-weight: 600 !important;
    padding: 10px 0px 10px 0px !important;
    margin: 0px 0px 0px 0px !important;
    text-align: center !important;
    border-bottom: 0px solid #e5e5e5 !important;
    border-color: #e5e5e5 !important;
}