.dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40px;
  background-color: #1976d2;
}

.dialog-header-text {
  color: white !important;
  font-size: 14px;
  margin: 0;
}

.dialog-header p:last-child {
  margin-right: 10px;
}

.dialog-closeicon {
  color: red !important;
  margin: 0;
  cursor: pointer;
  width: 0.75em !important;
}

.header-icon {
  color: white !important;
  font-size: 14px;
  margin-bottom: 5px;
  float: right;
}
