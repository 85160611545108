/* .icon-dropdown {
    position: relative;
  }
  
  .icon-dropdown__toggle {
    background-color: white;
    border: none;
    cursor: pointer;
    font-size: 16px;
    padding: 10px;
    display: flex;
    align-items: center;
  }
  
  .icon-dropdown__toggle svg {
    margin-left: 5px;
  }
  
  .icon-dropdown__menu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: green !important;
    border: 1px solid #ccc !important;
    border-top: none;
    width: 200px;
    padding: 10px;
    list-style: none;
    margin: 0;
    bottom: 0;
    left: 70%;
    transform: translateX(-50%);
  }
  
  .icon-dropdown__menu li {
    cursor: pointer;
    padding: 5px 0;
    color: black;
  }
  
  .icon-dropdown__menu li:hover {
    background-color: #f2f2f2;
  }
  .icon-align {
    margin-left: 110px !important;
  } */

.dropbtn {
  background-color: #04aa6d;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
  bottom: 0;
  left: 70%;
  transform: translateX(-50%);
  text-align: center;
  /* flex-direction: column; 
  align-items: center;  */
}

.admin-text {
  font-size: 14px; /* Adjust the font size as needed */
  text-align: center;
  margin-top: 4px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 100px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  top: 10px; /* move the dropdown up by 50px from the bottom */
  left: 0px; /* move the dropdown 10px to the left */
  transform: translateX(-100%);
}

.dropdown-content a {
  color: black;
  padding: 14px 18px;
  text-decoration: none;
  display: flex;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}

.icon-avatar {
  color: green !important;
  font-size: 20px !important;
  margin-right: 2px;
  cursor: pointer;
}
.icon-color {
  color: #3e8e41 !important;
}
.icon-size {
  font-size: 30px !important;
}
