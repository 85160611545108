.BoxChart {
    padding: 10px;
    display: "flex";
    justify-content: space-around;
    align-items: "center";
  }
  .GridCard {
    grid-column: span 3;
    background-color: #e0e0e0;
    grid-auto-flow: column;
    display: flex;
   align-items: center; 
   justify-content: center;
   gap: 10px;
  }
  .BoxMargin {
    margin: 5px;
  }
  .Grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-auto-rows: 160px;
    gap: 5px;
  }
  .SplitRowtwo {
    grid-column: span 6;
    grid-row: span 3;
    grid-auto-flow: column;
    background-color: #e0e0e0;
  }
  .Calcmar{
    margin: 20px;
  }
  .Calcdis{
   display: flex;
   justify-content: space-around;
  }
  .Calcdisplay{
   flex: 1 1 40%;
   margin-left: 5px;
  }
  .SplitRowthree {
    grid-column: span 12;
    grid-row: span 4;
    background-color: #e0e0e0;
  }
  .DisplayChart {
    height: 400px;
  }
  .Statbox{
      width: 100%;
      margin: 0 30px;
  }
  .BoxDisplay{
       display: flex;
       justify-content: space-between;
  }
  .Display{
     font-size: 20px;
  }
  .Displaying{
    font-family: 'Courier New', Courier, monospace;
    font-size: 20px;
    font-weight: bold;
    color: black;
  }
  @media (max-width: 480px) {
    .Grid{
       grid-auto-rows:100px;
   }
   .GridCard {
       grid-column: span 12;
       display: flex;
       grid-auto-flow: column;
       font-size: 20px;
   }
   .BoxMargin {
    margin: 5px;
  } 
   .BoxChart {
    display: "flex";
    font-size: 15px;
    justify-content: space-around;
  } 
   .Statbox{
     width: 100%;    
   }
  
   .BoxDisplay{
     font-size: 20px;
   }
  .Displaying{
  font-family: 'Courier New', Courier, monospace;
  font-size: 10px;
  font-weight: bold;
  color: black;
  }
  .DisplayChart {
    height: 350px;
    }
  .SplitRowtwo {
  grid-column: span 12;
  grid-row: span 4;
  grid-auto-flow: row;

  background-color  : #e0e0e0;
  }
  }
  @media (min-width: 481px) and (max-width: 768px) {
       .Grid{
          grid-auto-rows:100px;
      }
      .GridCard {
          grid-column: span 12;
          /* background-color: aqua; */
          display: flex;
          grid-auto-flow: row;
          /* font-size: 5px; */
      } 
      .Statbox{
        width: 100%;    
      }
  
      .BoxDisplay{
        font-size: 10px;
      }
   .Displaying{
    font-family: 'Courier New', Courier, monospace;
    font-size: 10px;
    font-weight: bold;
    color: black;
  }
  .BoxChart {
    /* padding: ; */
    display: "flex";
    justify-content: space-around;
    /* align-items: "center"; */
  }
  .SplitRowtwo {
    grid-column: span 12;
    grid-row: span 4;
    grid-auto-flow: row;
    background-color: #e0e0e0;
  }
  .BoxMargin {
    margin: 5px;
  }
  .DisplayChart {
    height: 350px;
  }
   
  }
  @media screen and (min-width: 769px) and (max-width: 1195px) {
    .Grid{
      grid-auto-rows:100px;
  }
  .GridCard {
      grid-column: span 6;
      display: flex;
      grid-auto-flow: row;
      font-size: 20px;
  } 
  .Statbox{
    width: 100%;    
  }
  
  .BoxDisplay{
    font-size: 10px;
  }
  .Displaying{
  font-family: 'Courier New', Courier, monospace;
  font-size: 10px;
  font-weight: bold;
  color: black;
  }
  .SplitRowtwo {
  grid-column: span 6;
  grid-row: span 4;
  grid-auto-flow: row;
  background-color: #e0e0e0;
  }
  .BoxMargin {
    margin: 5px;
  }
  .DisplayChart {
  height: 350px;
  }
}
  