/* .mydiv {
    width: 300px;
    height: 150px;
    border: 5px outset red;
    background-color: lightgray;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
}

h5 {
    text-align: center;
    color: red;
    margin-top: 40px;
  }

.retry-button {
    background-color: blue;
    border: none;
    color: white;
    padding: 10px;
    text-align: center;
    align-items: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    border-radius: 12px;
} */

/* .container {
    width: 100%;
    height: 100vh;
    background: gray;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
  } */

.mydiv {
    width: 400px;
    height: 220px;
    border: 5px outset gray;
    background-color: #fff;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
}

img {
    height: 70px;
    width: 70px;
    margin-top: 10px;
    align-items: center;
    justify-content: center;
}

.text {
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    margin-top: 10px;
    font-weight: 100;
    color: red;
}

.retry-button {
    background-color: blue;
    border: none;
    color: white;
    padding: 10px;
    text-align: center;
    align-items: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    border-radius: 12px;
    margin-top: 10px;
}